import React from 'react'
import { graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import MainContainer from '../../../container/main'
import SEO from '../../../components/seo'
import Container from '../../../components/container'
import ContentBannerWithCTA from '../../../components/content-banner-with-cta'
import { heroContainer, contentBlock } from './index.module.scss'
import { UseCaseBlock, UseCaseCategory, UseCaseHero, UseCasesContainer, UseCaseTeaser } from '../../../components/content-use-cases'
import ContentBlockSecondary from '../../../components/content-block-secondary'
import ContentBlockSmall from '../../../components/content-block-small'
import ContentBlockLargeWithGallery from '../../../components/content-block-large-gallery'
import Subheadline from '../../../components/content-subheadline'

const IndexPage = ({ data, location }) => (
  <MainContainer>
    <SEO title="Employee Onboarding" />

    <Container fullWidth style={{ background: 'var(--primary)' }}>
      <UseCaseCategory title="Employee Onboarding" />
    </Container>

    <Container>
      <UseCaseHero
        title="Impress new talents with a seamless digital experience"
        description="For growing companies, the onboarding of new employees is crucial. Modern companies therefore organize an optimal start with a defined onboarding process. With the welcome package the new employee receives promotional materials such as T-shirts in company design alongside with the hardware equipment. To get started, however, many additional appointments and tasks must be organized. And this is where the problem starts because there is typically no central solution for employees. To provide the best possible impression you should offer your employees a digital headquarters where they find everything in one single solution."
        actionLinkText="Get started – It's free!"
        actionLinkIsExternal
        actionLink="https://workplace.basaas.com/register/?utm_source=basaas-com&utm_medium=hero&utm_campaign=signup-basaas-2022&utm_content=lp-employee-onboarding"
        media={<StaticImage src="../assets/images/home-hero.png" alt="Enterprise Productivity Hub" placeholder="blurred" />}
        demoButton
      />
    </Container>

    <Container style={{ padding: '80px 0 0' }}>
      <Subheadline headline="Your digital headquarters as ideal starting point" />
      <span style={{ display: 'block', marginTop: '-8px', fontSize: '16px', fontWeight: '600', color: 'var(--primary)' }}>
        Provide a positive and streamlined experience for your new hires and welcome colleagues with a fully equipped Digital Workplace.
      </span>
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        // title="Work across apps and teams"
        // highlightColor="#FAD34C"
        content={[
          {
            linkTitle: 'Apps',
            title: 'All your apps in one solution',
            description: `Combine all apps for your new colleagues into your digital headquarters and help them to kickstart their new job.`,
            media: <StaticImage src="../../../assets/images/basaas-2022-apps.png" alt="All your apps in one solution" placeholder="blurred" />,
          },
        ]}
        alignVertically
      />
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        // title="Work across apps and teams"
        // highlightColor="#FAD34C"
        content={[
          {
            linkTitle: 'Tasks',
            title: 'Automated onboarding tasks',
            description: `Provide new colleagues a streamlined onboarding process with pre-defined onboarding tasks for a minimal time to productivity.`,
            media: <StaticImage src="../../../assets/images/basaas-2022-tasks.png" alt="Automated onboarding tasks" placeholder="blurred" />,
          },
        ]}
        alignVertically
        // reverse
      />
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        // title="Work across apps and teams"
        // highlightColor="#FAD34C"
        content={[
          {
            linkTitle: 'Search',
            title: 'Easily find all information',
            description: `Let your new colleagues find any information easily with a unified search, even if it's spread across different apps or websites.`,
            media: <StaticImage src="../../../assets/images/basaas-2022-search.png" alt="Easily find all information" placeholder="blurred" />,
          },
        ]}
        alignVertically
      />
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        // title="Work across apps and teams"
        // highlightColor="#FAD34C"
        content={[
          {
            linkTitle: 'MyDay',
            title: 'Digital Hub for the daily business',
            description: `Offer your new employees a Digital Hub where they can find everything to improve their workflows.`,
            media: <StaticImage src="../../../assets/images/basaas-2022-myday.png" alt="Digital Hub for the daily business" placeholder="blurred" />,
            // comingSoon: true,
          },
        ]}
        alignVertically
        // reverse
      />
    </Container>

    <Container style={{ background: 'var(--base-background)', padding: '160px 0 80px', marginTop: '160px' }}>
      <h2 style={{ fontSize: '24px', margin: '0 0 40px', fontWeight: 800 }}>Explore how to optimize your business for</h2>
      <UseCaseBlock location={location.pathname} />
    </Container>

    {/* BLOCK SECURITY  */}
    <Container style={{ background: 'var(--base-background)' }}>
      <ContentBlockSecondary
        headline="Enterprise productivity at the highest security level"
        description="The Basaas security concept is based on international best in class standards and EU requirements and guarantees the highest enterprise security level."
        // actionLinkText="Learn more"
        // actionLink="/tasks"
        item_01={
          <ContentBlockSmall
            key="1"
            headline="Fully GDPR compliant"
            description="Basaas is fully comply with European data security regulations including GDPR. For European companies, we guarantee that data storage takes place exclusively in the EU."
            // actionLinkText="Learn more"
            // actionLink="/workplace"
            media={<StaticImage src="../../../assets/feature-icons/gdpr-compliant.png" alt="Fully GDPR compliant" placeholder="blurred" />}
          />
        }
        item_02={
          <ContentBlockSmall
            key="2"
            headline="Proven enterprise security"
            description="At Basaas, we take our customers’ security very seriously and have extensive experience serving enterprise clients with complex security requirements."
            // actionLinkText="Learn more"
            // actionLink="/workplace"
            media={<StaticImage src="../../../assets/feature-icons/proven-enterprise-security.png" alt="Proven enterprise security" placeholder="blurred" />}
          />
        }
      />
    </Container>

    <Container style={{ background: 'var(--base-background)', padding: '80px 0' }}>
      <ContentBannerWithCTA />
    </Container>
  </MainContainer>
)

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        teamsCount
      }
    }
  }
`
